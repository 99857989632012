<template>
    <div class="editor-form__title editor-layout__content-form__field">
        <form-input
            v-model="title"
            :field.sync="title"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            :legend="naming.legend"
            :name="naming.name"
            :placeholder="naming.placeholder"
            @onChange="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorInput',
    props: [
        'value', 'errors', 'isValid',
        'disabled', 'naming'
    ],
    data() {
        return {
            title: this.value
        }
    },
    watch: {
        value(val) {
            this.title = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        }
    }
}
</script>
