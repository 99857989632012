<template>
    <div class="editor-form__description editor-layout__content-form__field">
        <form-textarea
            v-if="!disabled"
            v-model="description"
            :field.sync="description"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            :legend="naming.legend"
            :name="naming.name"
            :placeholder="naming.placeholder"
            @onChange="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorTextarea',
    props: [
        'value', 'errors', 'isValid', 'config',
        'disabled', 'naming'
    ],
    data() {
        return {
            description: this.value
        }
    },
    watch: {
        value(val) {
            this.description = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        }
    }
}
</script>

<style lang="scss">
</style>
