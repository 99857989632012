import Vue from 'vue'

// Validation
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { formMessages } from '@/validation/Messages'
import EditorCategorySelect from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorCategorySelect';
import EditorInput from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorInput';
import EditorInputLink from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorInputLink';
import EditorCheckbox from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorCheckbox';
import EditorSelect from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorSelect';
import EditorUserSelect from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorUserSelect';
import EditorTaskTypeSeclect from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorTaskTypeSeclect';
import EditorTaskStatuseSelect from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorTaskStatuseSelect';
import EditorPrioritiesSelect from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorPrioritiesSelect';
import EditorTextarea from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorTextarea';
import EditorContent from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorContent';
import EditorImg from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorImg';
import EditorSlug from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorSlug';
import EditorNumber from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorNumber';
import EditorDate from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorDate';

export default {
    components: {
        EditorCategorySelect,
        EditorInput,
        EditorCheckbox,
        EditorSelect,
        EditorTextarea,
        EditorContent,
        EditorImg,
        EditorSlug,
        EditorNumber,
        EditorUserSelect,
        EditorTaskTypeSeclect,
        EditorTaskStatuseSelect,
        EditorPrioritiesSelect,
        EditorDate,
        EditorInputLink
    },
    props: {
        mode_type: {
            type: String,
            required: true,
            validator(value) {
                return [
                    'create',
                    'preview',
                    'edit',
                    'revision'
                ].indexOf(value) !== -1
            }
        },
        fields: {
            type: Array,
            default: () => []
        },
        validationRules: {
            type: Object,
            default: () => ({})
        },
        form_data: {
            type: Object,
            default: () => ({})
        },
        serverErrors: {
            type: Object
        }
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations() {
        return this.validationRules
    },
    data() {
        return {
            timer: null,
            redirectTimer: null
        }
    },
    activated() {
        if (!this.form_data.locale) {
            this.form_data.locale = 'en'
        }
        if (this.contentType === 'task') {
            this.$v.$reset()
        }
    },
    beforeRouteLeave(to, from, next) {
        if (!to.name.includes(`create-post-${this.contentType}`)) {
            next()
            return
        }
        this.$v.$reset()
        this.$emit('reset')
        this.$nextTick(() => {
            next()
        })
    },
    watch: {
        redirect_to(value) {
            if (value) {
                this.redirect = 1
                return
            }
            this.redirect = 0
        },
        'form_data.id': function () {
            this.$nextTick(() => {
                Object.keys(this.form_data).forEach((key) => this.$v.form_data[key]?.$reset())
            })
        }
    },
    computed: {
        contentType() {
            return this.$route.meta.contentType
        },
        validateSlugActionName() {
            return `content${this.contentType[0].toUpperCase() + this.contentType.substring(1)}Edit/ACTION_VALIDATE_SLUG`
        },
        validateRedirectActionName() {
            return `content${this.contentType[0].toUpperCase() + this.contentType.substring(1)}Edit/ACTION_VALIDATE_REDIRECT`
        }
    },
    methods: {
        getNaming(item) {
            const {
                name,
                label,
                placeholder,
                legend
            } = item
            return {
                name,
                label,
                placeholder,
                legend
            }
        },
        validationMessage: validationMessage(formMessages),
        back() {
            this.$router.back()
        },
        async validateRedirect(redirect) {
            return new Promise((resolve, reject) => {
                clearTimeout(this.redirectTimer)
                this.redirectTimer = setTimeout(async () => {
                    const routeNames = [
                        `edit-post-${this.contentType}`,
                        `edit-post-essay-${this.contentType}`,
                        `create-post-${this.contentType}`
                    ]
                    if (!routeNames.includes(this.$route.name)) return
                    const options = {
                        redirect_to: redirect,
                        post_slug: this.form_data.post_slug
                    }
                    try {
                        await this.$store.dispatch(this.validateRedirectActionName, options)
                        resolve()
                    } catch {
                        reject()
                    }
                }, 500)
            })
        },
        async validateSlug(slug) {
            return new Promise((resolve, reject) => {
                clearTimeout(this.timer)
                this.timer = setTimeout(async () => {
                    const routeNames = [
                        `edit-post-${this.contentType}`,
                        `edit-post-essay-${this.contentType}`,
                        `create-post-${this.contentType}`
                    ]
                    if (!routeNames.includes(this.$route.name)) return
                    const options = {
                        post_slug: slug,
                        post_id: this.$route.params.id
                    }
                    try {
                        await this.$store.dispatch(this.validateSlugActionName, options)
                        resolve()
                    } catch {
                        reject()
                    }
                }, 500)
            })
        },
        imageHandler(event) {
            this.cover_image_url = event
        },
        inputHandeler(editorItem) {
            this.$v.form_data[editorItem.name].$touch()
            if (this.serverErrors && this.serverErrors[editorItem.name]) {
                Vue.delete(this.serverErrors, editorItem.name)
            }
        }
    }
}
