<template>
    <div class="editor-form__content editor-layout__content-form__field">
        <div
            class="editor-form__content__input"
            :class="{ 'error-border': validationError, 'success' : !validationError }"
        >
            <froala
                id="froala-editor"
                ref="froala"
                v-model="content"
                :tag="'textarea'"
                :config="froalaConfig"
                :field.sync="content"
                @input="onChange($event)"
            />
            <div
                v-if="validationError"
                class="editor-form__content__error"
            >
                {{ validationError }}
            </div>
        </div>
        <button
            class="btn-base btn-base_clear-format"
            @click.prevent="clearField"
        >
            Clear Formatting
        </button>
    </div>
</template>

<script>

import FroalaEditor from 'froala-editor';
import { config } from '@/helpers/froala';

export default {
    name: 'EditorContent',
    props: [
        'value', 'errors', 'isValid', 'config',
        'disabled', 'naming'
    ],
    data() {
        return {
            content: this.value ? this.value : '',
            froalaConfig: { ...config }
        }
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : '';
        }
    },
    watch: {
        value(val) {
            if (val) {
                this.content = val
            } else {
                this.content = ''
            }
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        },
        clearField() {
            new FroalaEditor('#froala-editor').commands.clearFormatting()
        }
    }
}
</script>

<style lang="scss">
.editor-form__content {
    &-block {
        position: relative;
    }
    .fr-view {
        @import "@/assets/styles/dashboard/components/_content-for-index.scss";
    }

    &--legend {
        font-family: 'Mark Pro', sans-serif;
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        border: none;
        z-index: 1;
        background: #fff;
        line-height: 16px;
    }

    &--content {
        border-radius: 4px;
        background: rgb(248, 248, 248);
        padding: 15px 10px;
    }
}
.fr-view {
    max-width: 890px;
    font-size: 16px!important;
    line-height: 24px!important;
    font-family: 'Mark Pro',sans-serif!important;
}
.fr-view p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
    color: #1f2939;
    margin-bottom: 16px;
}
.fr-view h2, .fr-view h3 {
    font-family: Mark Pro,sans-serif;
    line-height: 1.36;
    font-weight: 700;
    color: #1e2838;
    padding: 0;
    margin: 60px 0 40px;
}
.fr-view h2 {
    font-size: 26px;
}
.fr-view a {
    color: #ff8a1a!important;
    cursor: pointer;
}
.editor-form__content__input{
    border-radius: 10px;
    position: relative;
    &.success{
        border: 1px solid #27d91f;
    }
}
.editor-form__content__error{
    display: inline;
    width: auto;
    text-align: left;
    color: #ff5252;
    caret-color: #ff5252;
    font-size: 12px;
    min-height: 14px;
    position: absolute;
    left: 13px;
    bottom: -9px;
    background: white;
    padding: 0 3px;
}
</style>
