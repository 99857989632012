<template>
    <div class="editor-form__locale editor-layout__content-form__field">
        <form-select-status
            v-model="status"
            :field.sync="status"
            :init-value="status"
            :items="statuses"
            :label="naming.label"
            :name="naming.name"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            @input="onChange($event)"
        />
    </div>
</template>

<script>
import { mapTaskFields } from '@/store/modules/content/content-edit/tasks/index.js'

import {
    ACTION_GET_TASKS_STATUSES
} from '@/store/modules/content/content-edit/tasks/actions-types'

import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapContentEditActions
} = createNamespacedHelpers('contentTaskEdit');

export default {
    name: 'EditorChooseStatus',
    props: [
        'value', 'errors', 'isValid',
        'disabled', 'naming'
    ],
    data() {
        return {
            status: this.value
        }
    },
    computed: {
        ...mapTaskFields([
            'statuses'
        ])
    },
    watch: {
        value(val) {
            this.status = val
        }
    },
    created() {
        if (!this.status) {
            this.ACTION_GET_TASKS_STATUSES()
        }
    },
    methods: {
        ...mapContentEditActions([
            ACTION_GET_TASKS_STATUSES
        ]),
        onChange(event) {
            this.$emit('input', event)
            this.$emit('update:field', event)
        }
    }
}
</script>

<style scoped>

</style>
