<template>
    <div class="editor-form__link editor-layout__content-form__field">
        <form-input
            v-model="link"
            :field.sync="link"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            :legend="`https://www.customessaymeister.com/${type}/${value}`"
            :placeholder="`https://www.customessaymeister.com/${type}/your-link`"
            @onChange="onChange($event)"
        />
        <span
            v-if="!isEdit"
            class="editor-form__link_button"
            @click="changeFromTitle()"
        >
            <LinkVariant />
        </span>
    </div>
</template>

<script>
import LinkVariant from 'mdi-vue/LinkVariant.vue'

export default {
    name: 'EditorSlug',
    components: {
        LinkVariant
    },
    props: ['value', 'errors', 'isValid', 'config', 'disabled', 'type'],
    data() {
        return {
            link: this.value
        }
    },
    computed: {
        isEdit() {
            const { name } = this.$route
            const isEditLanding = name === 'edit-post-landing'
            const isEditBlog = name === 'edit-post-blog'
            const isEditEssay = name === 'edit-post-essay'
            if (isEditLanding || isEditBlog || isEditEssay) {
                return true
            }
            return false
        }
    },
    watch: {
        value(val) {
            this.link = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        },
        changeFromTitle() {
            let slug = document.querySelector('input[name="post_meta_title"]').value.trim()
            slug = slug.toLowerCase().replace(/\s/g, '-');
            this.onChange(slug)
        }
    }
}
</script>

<style scoped lang="scss">
    .editor-form__link{
        position: relative;
        &_button{
            cursor: pointer;
            background: #F2F2F2;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            border: none;
            border-radius: 4px;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        }
    }
</style>
