<template>
    <div class="editor-form__locale editor-layout__content-form__field">
        <form-select-priority
            v-model="priority"
            :field.sync="priority"
            :init-value="priority"
            :items="priorities"
            :label="naming.label"
            :name="naming.name"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            @input="onChange($event)"
        />
    </div>
</template>

<script>
import { mapTaskFields } from '@/store/modules/content/content-edit/tasks/index.js'

import {
    ACTION_GET_TASKS_PRIORITIES
} from '@/store/modules/content/content-edit/tasks/actions-types'

import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapContentEditActions
} = createNamespacedHelpers('contentTaskEdit');

export default {
    name: 'EditorPrioritiesSelect',

    props: [
        'value', 'errors', 'isValid',
        'disabled', 'naming'
    ],
    data() {
        return {
            priority: this.value
        }
    },
    computed: {
        ...mapTaskFields([
            'priorities'
        ])
    },
    watch: {
        value(val) {
            this.priority = val
        }
    },
    created() {
        if (!this.priorities || !this.priorities.length) {
            this.ACTION_GET_TASKS_PRIORITIES()
        }
    },
    methods: {
        ...mapContentEditActions([
            ACTION_GET_TASKS_PRIORITIES
        ]),
        onChange(event) {
            this.$emit('input', event)
            this.$emit('update:field', event)
        }
    }
}
</script>

<style scoped>

</style>
