<template>
    <div class="editor-form__locale editor-layout__content-form__field">
        <form-select-user
            v-model="assignee_id"
            :field.sync="assignee_id"
            :init-value="assignee_id"
            :label="naming.label"
            :name="naming.name"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            @input="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorChooseUser',
    props: [
        'value', 'errors', 'isValid',
        'disabled', 'naming'
    ],
    data() {
        return {
            assignee_id: this.value
        }
    },
    watch: {
        value(val) {
            this.assignee_id = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('input', event)
            this.$emit('update:field', event)
        }
    }
}
</script>

<style scoped>

</style>
