<template>
    <div class="editor-form__title editor-layout__content-form__field">
        <form-input
            v-model="number"
            :field.sync="number"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            :legend="naming.legend"
            :name="naming.name"
            :placeholder="naming.placeholder"
            type="number"
            @onChange="onChange($event)"
            @keypress="isNumber($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorNumber',
    props: [
        'value', 'errors', 'isValid',
        'disabled', 'naming'
    ],
    data() {
        return {
            number: ''
        }
    },
    watch: {
        value(val) {
            this.number = val
        }
    },
    created() {
        this.number = this.value
    },
    methods: {
        isNumber(e) {
            e = (e) || window.event;
            const charCode = (e.which) ? e.which : e.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                e.preventDefault()
                return false
            }
            return true;
        },
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        }
    }
}
</script>

<style scoped>

</style>
