<template>
    <div class="editor-form__category-select editor-layout__content-form__field">
        <form-select-category
            v-model="category"
            :field.sync="category"
            :items="categories_list"
            :label="naming.label"
            :name="naming.name"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            @input="onChange($event)"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'EditorChooseCategory',
    props: [
        'value', 'config', 'errors', 'isValid',
        'disabled', 'type', 'categories',
        'naming'
    ],
    data() {
        return {
            category: this.value
        }
    },
    computed: {
        ...mapState('contentCategory', ['selectCategories']),
        categories_list() {
            return this.selectCategories[this.type]
        }
    },
    watch: {
        value(val) {
            this.category = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('input', event)
            this.$emit('update:field', event)
        }
    }
}
</script>
