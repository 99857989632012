<template>
    <div class="editor-form__locale editor-layout__content-form__field">
        <form-date-picker
            v-model="date"
            label="Due date"
            tabindex="0"
            :min_hours="1"
            :in-future="true"
            :field.sync="date"
            :value="date"
            :prepend-value="date"
            :errors="errors"
            :is-valid="isValid"
            @change="onChange"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorDate',

    props: [
        'value', 'errors', 'isValid',
        'disabled', 'naming', 'prependValue'
    ],
    data() {
        return {
            date: null
        }
    },
    watch: {
        prependValue(val) {
            this.date = val
        }
    },
    created() {
        if (this.prependValue) {
            this.date = this.prependValue
        }
    },
    methods: {
        onChange(event) {
            this.$emit('input', event)
            this.$emit('update:field', event)
        }
    }
}
</script>

<style scoped>

</style>
