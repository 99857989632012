<template>
    <div class="editor-form__locale editor-layout__content-form__field">
        <form-select
            v-model="type"
            :field.sync="type"
            :items="types"
            :label="naming.label"
            :name="naming.name"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            @input="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorChoosePostType',
    props: [
        'value', 'errors', 'isValid',
        'disabled', 'naming'
    ],
    data() {
        return {
            type: this.value,
            types: [
                {
                    text: 'blog',
                    value: 'blog'
                },
                {
                    text: 'essay',
                    value: 'essay'
                },
                {
                    text: 'landing',
                    value: 'landing'
                }
            ]
        }
    },
    watch: {
        value(val) {
            this.type = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('input', event)
            this.$emit('update:field', event)
        }
    }
}
</script>

<style scoped>

</style>
