<template>
    <div class="editor-form">
        <div
            v-for="(editorItem, id) in fields"
            :key="id"
            class="editor-form__row"
            :style="editorItem.style"
        >
            <component
                :is="'editor-' + editorItem.component"
                v-model="$v.form_data[editorItem.name].$model"
                :type="contentType"
                :errors="(serverErrors && serverErrors[editorItem.name]) || validationMessage($v.form_data[editorItem.name])"
                :is-valid="$v.form_data[editorItem.name].$dirty && !$v.form_data[editorItem.name].$anyError"
                :naming="getNaming(editorItem)"
                :prepend-value="$v.form_data[editorItem.name].$model"
                @input.native="inputHandeler(editorItem)"
                @input="inputHandeler(editorItem)"
                @blur.native="$v.form_data[editorItem.name].$touch()"
                @imageInput="imageHandler($event)"
            />
        </div>
    </div>
</template>

<script>
import postPageMixins from '@/mixins/custom-editor/postPageMixins';

export default {
    name: 'ContentEditor',
    mixins: [postPageMixins]
}
</script>

<style scoped>
.editor-form {
    display: flex;
    flex-wrap: wrap;
}
</style>
